// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#FFF3F0',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '92px 0px'
  },
  mainContainer: {
    position: 'relative',
    maxWidth: 1500,
    width: '100%',
    padding: '0px 50px'
  }
  ,
  title: {
    fontSize: 40,
    fontWeight: 600,
    textTransform: 'uppercase',
    marginBottom: 80,
  },
  cardContainer: {
    height: 300,
    width: 300,
    background: theme.palette.primary.main,
    borderRadius: 8,
  },
  cardTitle: {
    fontSize: 32,
    fontWeight: 500,
    marginTop: 16,
    color: theme.palette.text.main
  },
  cardValue: {
    fontSize: 16,
    fontWeight: 400,
    marginTop: 10,
    color: theme.palette.text.secondary
  },
  cardIcon: {
    width: 80
  }
}))

export default useStyles