import { useState, useEffect } from 'react'

// AOS JS
import AOS from 'aos'
import 'aos/dist/aos.css'

// ASSETS
import BackgroundImage from 'assets/images/backgrounds/landing-faq-background.png'

// CUSTOM COMPONENTS
import CustomAccordion from 'components/Customs/CustomAccordion'
import CustomAccordionSummary from 'components/Customs/CustomAccordionSummary'

// MUIS
import AccordionDetails from '@mui/material/AccordionDetails'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// SERVICE
import { getLandingPageData } from 'services/landingPage'

// STYLES
import useStyles from './faqUseStyles'

const FAQ = () => {
  const classes = useStyles()

  const [ listData, setListData ] = useState([])

  const loadFAQData = async (inputIsMounted, inputAbortController) => {
    const resultFAQData = await getLandingPageData(inputAbortController.signal, 'FAQ')
  
    if(resultFAQData.FAQ !== undefined && inputIsMounted) {
      setListData(resultFAQData.FAQ)
    }
  }
  
  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()
  
    loadFAQData(isMounted, abortController)
    AOS.init()
  
    return () => {
      isMounted = false
      abortController.abort()
    }
  }, [])

  return (
    <Stack 
      marginTop='140px'
      width='100%'
      alignItems='center'
      sx={{ 
        backgroundImage: `url(${BackgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    >
      <Stack 
        maxWidth='1270px'
        padding='0px 50px'
        width='100%'
      >
        {/* TITLE */}
        <Stack
          alignItems='center'
          data-aos={'zoom-in'}
          data-aos-anchor-placement='top-bottom'
          data-aos-offset='-800'
          data-aos-duration='1000'
        >
          <Typography className={classes.title1}>
            { listData?.title }
          </Typography>
  
          <Typography className={classes.title2}>
            { listData?.description }
          </Typography>
        </Stack>
        <Stack
          width='100%'
          margin='0 auto'
          marginBottom='88px'
          data-aos={'zoom-out'}
          data-aos-anchor-placement='top-bottom'
          data-aos-offset='-800'
          data-aos-duration='1000'
        >
          {listData?.data?.map((item, index) =>
            <CustomAccordion
              key={index}
            >
              <CustomAccordionSummary>
                <Typography className={classes.faqSummary}>
                  { item.title }
                </Typography>
              </CustomAccordionSummary>
              <AccordionDetails>
                <Typography className={classes.faqDetail}>
                  { item.value }
                </Typography>
              </AccordionDetails>
            </CustomAccordion>
          )}
        </Stack>
      </Stack>
    </Stack>
  )
}

export default FAQ