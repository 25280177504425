export const values = {
  // ALL PAGES
  fontFamily: [ 'Poppins', 'sans-serif' ].join(','),

  // DRAWER
  drawerWidth: 256,
  drawerWidthOnMinimized: 80,
  
  // SNACKBAR
  initialSnackbarObject: {
    open: false,
    severity: 'success',
    title: '',
    message: '',
  },
}