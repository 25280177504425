// COMPONENTS
import Header from './Header/Header'
import Jumbotron from './Jumbotron/Jumbotron'
import Benefits from './Benefits/Benefits'
import Technology from './Technology/Technology'
import Solution from './Solution/Solution'
import Features from './Features/Features'
import Footer from './Footer/Footer'
import ReportFlow from './ReportFlow/ReportFlow'
import FAQ from './FAQ/FAQ'
import Testimonies from './Testimonies/Testimonies'
import ContactUs from './ContactUs/ContactUs'

//MUIS
import Stack from '@mui/material/Stack'

const Landing = () => {

  return (
    <Stack overflow='hidden'>
      {/* HEADER */}
      <Header/>

      {/* JUMBOTRON */}
      <Jumbotron/>

      {/* Benefits */}
      <Benefits/>

      {/* TECHNOLOGY */}
      <Technology/>

      {/* SOLUTION */}
      <Solution/>

      {/* FEATURES */}
      <Features/>

      {/* REPORT FLOW */}
      <ReportFlow/>

      {/* FAQ */}
      <FAQ/>

      {/* TESTIMONIES */}
      <Testimonies/>

      {/* CONTACT US */}
      <ContactUs/>

      {/* FOOTER */}
      <Footer/>
    </Stack>
  )
}

export default Landing