// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  logoContainer: {
    width: '100%',
    height: 40,
    background: '#7D3325'
  },
  headerText: {
    fontSize: 12,
    fontWeight: 400,
    color: 'white'
  },
  kemenkeuLogo: {
    marginRight: 8,
  },
  appBarMainContainer: {
    background: '#FEF7F7',
    width: '100%',
    paddingTop: 15
  },
  appBarContainer:{
    maxWidth: 1500,
    padding: '0px 50px',
    width: '100%'
  },
  productLogo: {
    paddingTop: 12.5,
    height: 47,
    cursor: 'pointer'
  },
  buttongroup: {
    color: '#444444',
    textTransform: 'none'
  },
  rightButtons: {
    height: 56,
  },
  loginButton: {
    background: '#2196F3',
    color: 'white',
    textTransform: 'none',
    width: 115,

    '&:hover': {
      borderColor: '#2196F3',
      background: '#2196F310',
      color: '#2196F3',
    }
  },
  bookingOrderButton: {
    background: '#F44336',
    color: 'white',
    textTransform: 'none',

    '&:hover': {
      borderColor: '#F44336',
      color: '#F44336',
    }
  }
}))

export default useStyles