// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  title1: {
    fontSize: 34,
    fontWeight: 600,
    color: theme.palette.text.primary,
    marginBottom: 10,
  },
  title2: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  fieldName: {
    fontSize: 16,
    fontWeight: 700,
    color: theme.palette.text.secondary,
    marginTop: 23,
    marginBottom: 16,
  },
  sectionMap: {
    position: 'relative',
    '&.leaflet-container': {
      width: '100%',
      height: '100%',
    },
  },
  formButtonContainer: {
    marginTop: 28,
    width: '100%',
  },
  formButton: {
    flex: 1,
  },
  cardContainer: {
    position: 'absolute',
    maxWidth: '584px',
    width: '100%',
    zIndex: 400,
    alignContent: 'center',
    backgroundColor: 'white',
    top: '560px',
    marginRight: '',
    padding: '30px 16px',
    borderRadius: 8,
    boxShadow: '0px 4px 40px rgba(0, 0, 0, 0.04)',
    cursor: 'pointer',
  },
  cardIcon: {
    position: 'absolute',
    maxWidth: '70px',
    width: '100%',
    zIndex: 500,
    alignContent: 'center',
    margin: '560px 40px 0',
    marginLeft: '495px',
    padding: '24px 27px',
    cursor: 'pointer',
  },
  cardTitle: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  cardAddress: {
    fontSize: 24,
    fontWeight: 700,
  },
}))

export default useStyles