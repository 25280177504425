import { useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'

// ASSETS
import LogoProductIconOnly from 'assets/images/logos/product-nle-connect-icon-only.svg'
import LogoProductIconOnlyPng from 'assets/images/logos/product-nle-connect-icon-only.png'

// COMPONENTS
import Landing from './Pages/Landing/landing'

function App() {
  
  useEffect(() => {
    // UPDATE FAVICON
    const faviconElement = document.getElementById('favicon')
    faviconElement.href = LogoProductIconOnly
  
    const appleTouchIconElement = document.getElementById('apple-touch-icon')
    appleTouchIconElement.href = LogoProductIconOnlyPng
  }, [])

  return (
    <Routes>
      <Route path={'/'} element={<Landing/>}/>
    </Routes>
  )
}

export default App
