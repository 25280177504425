// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  nleLogo:{
    width: 147,
    marginBottom: 32,
  },
  title1: {
    fontSize: 48,
    fontWeight: 600,
    color: theme.palette.text.primary,
    marginBottom: 24,
  },
  title2: {
    fontSize: 22,
    fontWeight: 400,
    color: theme.palette.text.secondary,
    marginBottom: 60,
  },
  cardContainer: {
    padding: '40px 32px',
    background: 'rgba(77, 191, 170, 0.08)'
  },
  numberContainer: {
    display: 'flex',
    width: 72,
    height: 72,
    justifyContent: 'center',
    alignItems: 'center',
    background: 'white',
    marginBottom: 24,
  },
  numberFontSize: {
    fontSize: 20,
    fontWeight: 700,
    color: theme.palette.primary.main
  },
  cardTitle: {
    fontSize: 20,
    fontWeight: 700,
    marginBottom: 16,
    color: theme.palette.text.primary,
  },
  cardDescription: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.primary,
  }
}))

export default useStyles