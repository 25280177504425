import { useNavigate } from 'react-router-dom'

// ASSETS
import KemenkeuLogo from 'assets/images/logos/landing-kemenkeu.svg'
import LogoproductNleConnect from 'assets/images/logos/product-nle-connect.svg'

// MUIS
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// STYLES
import useStyles from './headerUseStyles'

const Header = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  
  const depoDeployedUrl = process.env.REACT_APP_DEPLOYED_DEPO_APP_URL
  const bookingOrderDeployedUrl = process.env.REACT_APP_DEPLOYED_BOOKING_ORDER_APP_URL

  const handleClickSignInButton = () => {
    window.open(`${depoDeployedUrl}/sign-in`)
  }

  const handleClickBookingOrderButton = () => {
    window.open(`${bookingOrderDeployedUrl}`)
  }

  return (
    <>
      {/* KEMENKEU LOGO AND CAPTION */}
      <Stack 
        className={classes.logoContainer}
        justifyContent='center'
        alignItems='center'
      >
        <Stack 
          maxWidth='1500px'
          padding='0px 50px'
          direction='row' 
          alignItems='center'
          justifyContent='flex-start'
          width='100%'
        >
          <Box
            component='img'
            src={KemenkeuLogo}
            className={classes.kemenkeuLogo}
          />
          <Typography className={classes.headerText}>
            Mitra resmi dan didukung oleh KEMENKEU RI
          </Typography>
        </Stack>
      </Stack>

      {/* APP BAR */}
      <Stack 
        className={classes.appBarMainContainer}
        alignItems='center'
      >
        <Stack 
          className={classes.appBarContainer}
          direction='row'
          justifyContent='space-between'
        >
          {/* NLE CONNECT LOGO */}
          <Box
            component='img'
            src={LogoproductNleConnect}
            className={classes.productLogo}
            onClick={() => navigate('/')}
          />

          {/* BUTTON GROUP */}
          <Stack direction='row' spacing={2}>
            {/* FITUR */}
            {/* <Button className={classes.buttongroup}>
              Fitur
            </Button> */}

            {/* FITUR */}
            {/* <Button className={classes.buttongroup}>
              Cara Kerja
            </Button> */}

            {/* FITUR */}
            {/* <Button className={classes.buttongroup}>
              Hubungi Kami
            </Button> */}
          </Stack>

          <Stack className={classes.rightButtons} direction='row' spacing={2}>
            {/* LOGIN BUTTON */}
            <Button
              className={classes.loginButton}
              variant='outlined' 
              size='large'
              onClick={() => handleClickSignInButton()}
            >
              Login
            </Button>
            <Button
              className={classes.bookingOrderButton}
              variant='outlined' 
              size='large'
              onClick={() => handleClickBookingOrderButton()}
            >
              Create Booking Order
            </Button>
          </Stack>
        </Stack>
      </Stack>
      
    </>
  )
}

export default Header