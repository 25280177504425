// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingTop: 142,
  },
  captionText: {
    fontSize: 48,
    fontWeight: 700,
    marginBottom: 40,
  },
  descriptionText: {
    fontSize: 24,
    fontWeight: 400,
    marginBottom: 28.01,
  },
  devicesImage: {
    maxWidth: 800,
    width: '100%',
    display: 'flex'
  }
}))

export default useStyles