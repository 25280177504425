//MUI
import MuiAccordion from '@mui/material/Accordion'
import { styled } from '@mui/material/styles'

const CustomAccordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderLeft: 0,
  borderRight: 0,
  backgroundColor: 'rgba(0, 0, 0, 0)',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}))

export default CustomAccordion