// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  jumboTronMainContainer: {
    width: '100%',
    background: '#FEF7F7',
    marginBottom: 104
  },
  jumboTronContainer: {
    width: '100%',
    maxWidth: 1500,
    padding: '88px 50px 0px 50px',
  },
  logosContainer: {
    width: '100%'
  },
  captionText: {
    fontSize: 48,
    fontWeight: 700,
    marginBottom: 246,
  },
  signUpButton: {
    width: 116,
    marginBottom:160,
  },
  totalTripNumber: {
    fontSize: 28,
    fontWeight: 600,
  },
  totalTripicon: {
    color: '#4DBFAA'
  },
  totalTripTitle: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: 14
  },
  jumboImage: {
    maxWidth: 827.32,
    width: '100%',
    display: 'flex'
  }
}))

export default useStyles