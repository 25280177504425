//MUI
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import { styled } from '@mui/material/styles'

// MUI ICONS
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const CusotmAccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreIcon/>}{...props}/>
))(() => ({
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
    color:'#F96649',
  },
}))
  
export default CusotmAccordionSummary