import { useState, useEffect } from 'react'

// AOS JS
import AOS from 'aos'
import 'aos/dist/aos.css'

// ASSETS
import Devicesimage from 'assets/images/backgrounds/landing-technology-main-image.png'
import MainBackground from 'assets/images/backgrounds/landing-technology-background.png'

// MUIS
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// SERVICE
import { getLandingPageData } from 'services/landingPage'

// STYLES
import useStyles from './technologyUseStyles'

const Technology = () => {
  const classes = useStyles()

  const [ listData, setListData ] = useState([])
  const loadTechnologyData = async (inputIsMounted, inputAbortController) => {
    const resultTechnologyData = await getLandingPageData(inputAbortController.signal, 'technology')
    
    if(resultTechnologyData.technology !== undefined && inputIsMounted) {
      setListData(resultTechnologyData.technology)
    }
  }

  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()

    loadTechnologyData(isMounted, abortController)
    AOS.init()

    return () => {
      isMounted = false
      abortController.abort()
    }
  }, [])

  return (
    <>
      {/* TECHNOLOGY */}
      <Stack 
        alignItems='center' 
        className={classes.root}
        sx={{ 
          backgroundImage: `url(${MainBackground})`,
          backgroundPosition: 'center'
        }}
      >
        <Stack
          direction='row'
          width='100%'
          maxWidth='1500px'
          padding='0px 50px'
        >
          {/* LEFT CONTENT */}
          <Stack
            direction='column'
            justifyContent='flex-start'
            maxWidth='470px'
          >
            {/* CAPTION TEXT */}
            <Typography
              className={classes.captionText}
              data-aos={'fade-up'}
              data-aos-duration='1000'
              data-aos-offset='-100'
              data-aos-anchor-placement='top-bottom'>
              { listData?.title }
            </Typography>
            {/* DESCRIPTION TEXT */}
            <Stack data-aos={'fade-up'} data-aos-duration='1000' data-aos-anchor-placement='top-bottom'>
              {listData?.data?.map((item,index) => (
                <Typography key={index} className={classes.descriptionText}>
                  { item.value }
                </Typography>
              ))}
            </Stack>
          </Stack>

          {/* RIGHT CONTENT */}
          <Stack 
            width='100%'
            direction='row'
            justifyContent='flex-end'
            paddingBottom='48.61px'
            data-aos={'fade-up-left'}
            data-aos-anchor-placement='top-bottom'
            data-aos-offset='-100'
            data-aos-duration='1000'
          >
            <Box
              component='img'
              src={Devicesimage}
              className={classes.devicesImage}
            />
          </Stack>
        </Stack>
      </Stack>
    </>
  )
}

export default Technology