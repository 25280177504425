// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  mainTitle: {
    fontSize: 40,
    fontWeight: 600,
    marginBottom: 80,
  },
  secondaryPath: {
    marginBottom: 100
  },
  iconContainer: {
    width: 80,
    height: 80,
    background: 'rgba(77, 191, 170, 0.08)',
    backdropFilter: 'blur(15px)',
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  itemContainer: {
    position: 'absolute',
    top: '17.5%',
    left: '29%'
  },
  itemIcon: {
    width: 40,
    height: 40
  },
  tes: {
    bottom: '5%'
  }
}))

export default useStyles