// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  mainTitle: {
    fontSize: 48,
    fontWeight: 600,
    whiteSpace: 'pre-line',
  },
  iconButton: {
    '&:hover' : {
      backgroundColor: 'transparent'
    }
  },
  prevIcon: {
    width: 60,
    height: 60,
    color: 'rgba(0, 0, 0, 0.54)',
    '&:hover' : {
      color: theme.palette.primary.main
    },
  },
  nextIcon: {
    width: 60,
    height: 60,
    color: 'rgba(0, 0, 0, 0.54)',
    '&:hover' : {
      color: theme.palette.primary.main
    }
  },
  cardContainer: {
    position: 'absolute',
    backgroundColor: 'white',
    padding: '33px 24px',
    borderRadius: 20,
    boxShadow: '0px 4px 40px rgba(0, 0, 0, 0.04)',
    width: 392,
  },
  cardTitle: {
    fontSize: 18,
    fontWeight: 500,
    marginBottom: 67,
  },
  listItem: {
    padding: 0
  },
  ListItemText : {
    '& .MuiListItemText-primary' : {
      fontSize: 16,
      fontWeight: 600,
      color: theme.palette.text.primary
    },
    '& .MuiListItemText-secondary' : {
      fontSize: 14,
      fontWeight: 400,
      color: theme.palette.text.secondary
    }
  }
}))

export default useStyles