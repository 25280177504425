import { useState, useEffect } from 'react'

// AOS JS
import AOS from 'aos'
import 'aos/dist/aos.css'

// MUIS
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// SERVICE
import { getLandingPageSpecificData } from 'services/landingPage'

// STYLES 
import useStyles from './featuresUseStyles'

// SWIPER JS || https://swiperjs.com/demos#effect-coverflow
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/effect-coverflow'
import { EffectCoverflow } from 'swiper'
import './styles.css'

const Features = () => {
  const classes = useStyles()

  const [ isDataLoaded, setIsDataLoaded ] = useState(false)
  const [ listData, setListData ] = useState([])

  const loadFeaturesData = async (inputIsMounted, inputAbortController) => {
    const resultFeaturesData = await getLandingPageSpecificData(inputAbortController.signal, 'features')

    if(resultFeaturesData.features !== undefined && inputIsMounted) {
      setListData(resultFeaturesData.features)
      setIsDataLoaded(true)
    }
  }

  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()

    loadFeaturesData(isMounted, abortController)
    AOS.init()

    return () => {
      isMounted = false
      abortController.abort()
      setIsDataLoaded(false)
    }
  }, [])

  return (
    <Box className={classes.root}>
      <Box className={classes.mainContainer}>
        {/* TITLE */}
        <Typography 
          className={classes.title}
          textAlign='center'
          data-aos='fade-down'
          data-aos-duration='1000'
          data-aos-anchor-placement='top-bottom'
          data-aos-offset='-300'
        >
          {listData?.title}
        </Typography>
        {isDataLoaded &&
        <Swiper
          data-aos='fade-up'
          data-aos-duration='1000'
          data-aos-anchor-placement='top-bottom'
          data-aos-offset='-250'
          effect={'coverflow'}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={'auto'}
          loop={true}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 200,
            modifier: 1,
            slideShadows: false,
          }}
          modules={[EffectCoverflow]}
          className='mySwiper'
        >
          {listData?.data?.map((item, index) => (
            <SwiperSlide key={index}>
              <Stack 
                className={`${classes.cardContainer} cardContainer`}
                alignItems='center'
                justifyContent='center'
              >
                <Box
                  component='img'
                  src={`https://nle-strapi.app.lacak.io${item?.icon?.data[0]?.attributes?.url}`}
                  className={classes.cardIcon}
                />
              </Stack>

              <Typography 
                textAlign='center'
                className={`${classes.cardTitle} cardTitle`}
              >
                {item.title}
              </Typography>

              <Typography 
                textAlign='center'
                className={`${classes.cardValue} cardValue`}
              >
                {item.value}
              </Typography>
            </SwiperSlide>
          ))}
        </Swiper>}
      </Box>
    </Box>
  )
}

export default Features