import { useState, useEffect, useRef } from 'react'

// AOS JS
import AOS from 'aos'
import 'aos/dist/aos.css'

// ASSETS
import BackgroundImage from 'assets/images/backgrounds/landing-testimonies-background.png'

// MUIS
import { IconButton, Stack, Typography } from '@mui/material'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar'

// MUI ICONS
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import PersonIcon from '@mui/icons-material/Person'

// SERVICE
import { getLandingPageSpecificData } from 'services/landingPage'

// STYLES
import useStyles from './testimoniesUseStyles'

// SWIPER
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/free-mode'
import './styles.css'


const Testimonies = () => {
  const classes= useStyles()
  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)

  const [ isDataLoaded, setIsDataLoaded ] = useState(false)
  const [ listData, setListData ] = useState([])

  const loadTestimoniesData = async (inputIsMounted, inputAbortController) => {
    const resultTestimoniesData = await getLandingPageSpecificData(inputAbortController.signal, 'testimonies')

    if(resultTestimoniesData.testimonies !== undefined && inputIsMounted) {
      setListData(resultTestimoniesData.testimonies)
      setIsDataLoaded(true)
    }
  }

  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()

    loadTestimoniesData(isMounted, abortController)
    AOS.init()

    return () => {
      isMounted = false
      abortController.abort()
      setIsDataLoaded(false)
    }
  }, [])

  return (
    <Stack
      width='100%'
      alignItems='center'
      padding='100px 30px 134px 30px'
      sx={{ 
        backgroundImage: `url(${BackgroundImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }}
      data-aos={'fade-up'}
      data-aos-anchor-placement='top-bottom'
      data-aos-offset='-900'
      data-aos-duration='1000'
    >
      {/* TITLE */}
      <Stack
        maxWidth='1270px'
        padding='0px 50px'
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        width='100%'
        marginBottom='100px'
      >
        <Typography
          className={classes.mainTitle}
          data-aos={'fade-up'}
          data-aos-anchor-placement='top-bottom'
          data-aos-offset='-800'
          data-aos-duration='1000'
        >
          {listData?.description}
        </Typography>

        {/* ICONS */}
        <Stack
          direction='row'
          spacing={3}
          data-aos={'fade-up'}
          data-aos-anchor-placement='top-bottom'
          data-aos-offset='-600'
          data-aos-duration='1000'
        >
          <IconButton ref={navigationPrevRef} className={classes.iconButton}>
            <KeyboardArrowLeftIcon className={classes.prevIcon}/>
          </IconButton>

          <IconButton ref={navigationNextRef} className={classes.iconButton}>
            <KeyboardArrowRightIcon className={classes.nextIcon}/>
          </IconButton>
        </Stack>
      </Stack>

      {/* CAROUSEL */}
      {isDataLoaded &&
      <Swiper
        onInit={(swiper) => {
          swiper.params.navigation.prevEl = navigationPrevRef.current
          swiper.params.navigation.nextEl = navigationNextRef.current
          swiper.navigation.init()
          swiper.navigation.update()
        }}
        navigation={{
          prevEl: navigationPrevRef.current ? navigationPrevRef.current : undefined,
          nextEl: navigationNextRef.current ? navigationNextRef.current : undefined,
        }}
        grabCursor={true}
        slidesPerView={'auto'}
        loop={true}
        spaceBetween={120}
        freeMode={true}
        modules={[FreeMode, Navigation]}
        className='mySwiper'
        data-aos={'fade-up'}
        data-aos-anchor-placement='top-bottom'
        data-aos-offset='-800'
        data-aos-duration='1000'
      >
        {listData?.data?.map((item, index) => (
          <SwiperSlide key={index}>
            <Stack className={classes.cardContainer}> 
              <Typography className={classes.cardTitle}>
                {item.value} 
              </Typography>

              <Stack>
                <List>
                  <ListItem className={classes.listItem}>
                    <ListItemAvatar>
                      <Avatar>
                        <PersonIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText 
                      className={classes.ListItemText} 
                      primary={item.title} 
                      secondary={item.addition} 
                    />
                  </ListItem>
                </List>
              </Stack>
            </Stack>
          </SwiperSlide>
        ))}
      </Swiper>}
    </Stack>
  )
}

export default Testimonies