// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 20,
    fontWeight: 700,
    color: theme.palette.text.primary,
    marginBottom: 27.53,
  },
  nleConnenctLogo:{
    height: '40px',
    width: '136px',
    marginLeft: '5.5px',
    cursor: 'pointer'
  },
  transportaLogo: {
    margin: '5.6px 5.99px',
    height: '15.96px',
    cursor: 'pointer'
  },
  kemenkeuLogo: {
    height: '40.00666046142578px',
    width: '154px',
    marginBottom: '16px',
  },
  nleLogo: {
    height: '40px',
    width: '119.9058837890625px',
    borderRadius: '4px',
  },
  link: {
    '&:hover':{
      cursor: 'pointer',
      textDecoration: 'underline'
    }
  },
  copyright: {
    fontFamily: 'DM Sans',
    fontSize: 16,
    fontWeight: 400,
  }
}))

export default useStyles