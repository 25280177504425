import { useState, useEffect  } from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import L from 'leaflet'

// AOS JS
import AOS from 'aos'
import 'aos/dist/aos.css'

// ASSETS
import IconPlace from 'assets/images/logos/marker-icon.svg'
import IconPlaceInside from '@mui/icons-material/Place'

// MUIS
import Button from '@mui/material/Button'
import FilledInput from '@mui/material/FilledInput'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import MuiAlert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'

// SERVICE
import { getLandingPageData, postContactUs } from 'services/landingPage'

// STYLES
import useStyles from './contactUsUseStyles'
import 'leaflet/dist/leaflet.css'

const markerIcon = new L.icon({
  iconUrl: IconPlace,
  iconSize: [ 100, 100 ],
})

const ContactUs = () => {
  const classes = useStyles()
  const valid = [-6.108263576653714, 106.74050003808851]

  const initialForm = {
    name: '',
    email: '',
    category: '',
    message: '',
  }

  const [ isDataLoaded, setIsDataLoaded ] = useState(false)
  const [ listData, setListData ] = useState([])
  const [contactUsForm, setContactUsForm] = useState(initialForm)
  const [openSnackbar, setOpenSnackbar] = useState(false)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return

    setOpenSnackbar(false)
  }

  const handleContactUsOrderFormChange = (event) => {
    setContactUsForm(current => ({
      ...current,
      [event.target.name]: event.target.value,
    }))
  }

  const handleIsButtonDisabe = () => {
    if(contactUsForm.name !== '' && contactUsForm.email !== '' && contactUsForm.category !== '' && contactUsForm.message !== '') return false
    return true
  }

  const handleSendButton = async () => {
    const abortController = new AbortController()
    const bodyParams = {
      fullName: contactUsForm.name,
      email: contactUsForm.email,
      category: contactUsForm.category.toUpperCase(),
      message: contactUsForm.message,
    }
    const resultPostContactUs = await postContactUs(abortController.signal, bodyParams)
    if(resultPostContactUs.status === 200) {
      setContactUsForm(initialForm)
      setOpenSnackbar(true)
    }
    abortController.abort()
  }

  const loadContactUsData = async (inputIsMounted, inputAbortController) => {
    const resultContactUsData = await getLandingPageData(inputAbortController.signal, 'contactUs')
  
    if(resultContactUsData.contactUs !== undefined && inputIsMounted) {
      setListData(resultContactUsData.contactUs.data)
      setIsDataLoaded(true)
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }
  }

  const handleOpenMapClick = () => {
    const newWindow = window.open(
      'https://goo.gl/maps/r8dLMN6zYGacnf2d9',
      '_blank',
      'noopener,noreferrer'
    )

    if (newWindow) newWindow.opener = null
  }

  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()
  
    loadContactUsData(isMounted, abortController)
    AOS.init()
  
    return () => {
      isMounted = false
      abortController.abort()
      setIsDataLoaded(false)
    }
  }, [])

  return (
    <>
      {/* CONTACT US */}
      {isDataLoaded &&
      <Stack
        marginTop='165px'
        marginBottom='165px'
        alignItems='center'
      >
        <Stack
          direction='row'
          maxWidth='1500px'
          padding='0px 50px'
          width='100%'
        >
          {/* LEFT CONTENT */}
          <Stack
            direction='column'
            justifyContent='flex-start'
            maxHeight='800px'
            maxWidth='728px'
            position='relative'
            width='100%'
            data-aos={'fade-right'}
            data-aos-anchor-placement='top-bottom'
            data-aos-offset='-1300'
            data-aos-duration='1000'
          >
            <MapContainer
              className={classes.sectionMap}
              center={[-6.138463576653714, 106.74050003808851]}
              zoom={13}
              maxZoom={18}
              scrollWheelZoom={false}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
              />
              <Marker 
                position={ valid }
                icon={ markerIcon }
              >
                <Popup>
                  PIK Avenue Mall, Lt 6,<br/> Jalan Pantai Indah Kapuk Boulevard
                </Popup>
                <Stack
                  direction='row'
                  justifyContent='center'
                  onClick={() => handleOpenMapClick()}
                >
                  <Stack className={classes.cardContainer}>
                    <Typography className={classes.cardTitle}>
                      { listData[0].title }
                    </Typography>
                    <Typography className={classes.cardAddress}>
                      { listData[0].value }
                    </Typography>
                  </Stack>
                  <Stack 
                    className={classes.cardIcon}
                    direction='column'
                    justifyContent='flex-end'
                    paddingLeft='130px'
                  >
                    <IconPlaceInside sx={{ fontSize:67, color:'#F96649'}}/>
                  </Stack>
                </Stack>
              </Marker>
            </MapContainer>
          </Stack>
          {/* RIGHT CONTENT */}
          <Stack 
            width='100%'
            direction='column'
            justifyContent='flex-end'
            paddingLeft='130px'
            data-aos={'fade-left'}
            data-aos-anchor-placement='top-bottom'
            data-aos-offset='-1300'
            data-aos-duration='1000'
          >
            {/* TITLE */}
            <Stack>
              <Typography className={classes.title1}>
                { listData[1].title }
              </Typography>
              <Typography className={classes.title2}>
                { listData[1].value }
              </Typography>
            </Stack>
            {/* FORM */}
            {/* NAME */}
            <Typography className={classes.fieldName}>
              Nama lengkap
            </Typography>
            <FormControl
              variant='filled'
              hiddenLabel
              className='formTextField'
              color='secondary'
            >
              <FilledInput 
                placeholder='Jawab di sini'
                type='text'
                name='name'
                value={contactUsForm.name}
                onChange={(event) => handleContactUsOrderFormChange(event)}
              />
            </FormControl>

            {/* EMAIL */}
            <Typography className={classes.fieldName}>
              Alamat email
            </Typography>
            <FormControl
              variant='filled'
              hiddenLabel
              className='formTextField'
              color='secondary'
            >
              <FilledInput 
                placeholder='Jawab di sini'
                type='email'
                name='email'
                value={contactUsForm.email}
                onChange={(event) => handleContactUsOrderFormChange(event)}
              />
            </FormControl>

            {/* CATEGORY */}
            <Typography className={classes.fieldName}>
              Kategori
            </Typography>
            <FormControl
              variant='filled'
              hiddenLabel
              className='formTextField'
              color='secondary'
            >
              <RadioGroup
                name='category'
                value={contactUsForm.category}
                onChange={(event) => handleContactUsOrderFormChange(event)}
              >
                <Stack direction='row'>
                  <Stack>
                    <FormControlLabel value='Berlangganan' control={<Radio />} label='Berlangganan' />
                    <FormControlLabel value='Sistem' control={<Radio />} label='Sistem' />
                    <FormControlLabel value='Produk' control={<Radio />} label='Produk' />
                  </Stack>
                  <Stack marginLeft='80px'>
                    <FormControlLabel value='Laporan' control={<Radio />} label='Laporan' />
                    <FormControlLabel value='API' control={<Radio />} label='API' />
                    <FormControlLabel value='Lainnya' control={<Radio />} label='Lainnya' />
                  </Stack>
                </Stack>
              </RadioGroup>
            </FormControl>  

            {/* MESSAGE */}
            <Typography className={classes.fieldName}>
              Pesan
            </Typography>
            <FormControl
              variant='filled'
              hiddenLabel
              className='formTextField'
              color='secondary'
            >
              <FilledInput 
                placeholder='Jawab di sini'
                multiline
                type='text'
                name='message'
                value={contactUsForm.message}
                onChange={(event) => handleContactUsOrderFormChange(event)}
              />
            </FormControl>
            {/* DIALOG ACTIONS */}
            <Stack
              direction='column'
              className={classes.formButtonContainer}
              height='42px'
            >
              {/* SEND BUTTON */}
              <Button
                disabled={handleIsButtonDisabe()}
                className={classes.formButton}
                variant='contained'
                onClick={() => handleSendButton()}
              >
                Kirim
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>}

      {/* SNACKBAR */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ 
          vertical: 'bottom', 
          horizontal: 'left', 
        }}
      >
        <MuiAlert 
          onClose={handleClose} 
          elevation={6} 
          variant='filled' 
          severity='success'
        >
          Successfully send the question
        </MuiAlert>
      </Snackbar>
    </>
  )
}

export default ContactUs