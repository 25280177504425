import axios from 'axios'

const landingURL = process.env.REACT_APP_API_LANDING_URL
const depoURL = process.env.REACT_APP_API_DEPO_URL
const headers = { 'Content-Type': 'application/json' }

export default axios.create({
  baseURL: landingURL,
  headers: headers,
})

export const axiosLanding = axios.create({
  baseURL: landingURL,
  headers: headers,
})

export const axiosDepo = axios.create({
  baseURL: depoURL,
  headers: headers
})