import React from 'react'

// ASSETS
import KemenkeuLogo from 'assets/images/logos/landing-kemenkeu-medium.svg'
import NleConectLogo from 'assets/images/logos/product-nle-connect.svg'
import NleLogo from 'assets/images/logos/landing-nle.svg'
import TransportaLogo from 'assets/images/logos/landing-transporta.svg'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import TwitterIcon from '@mui/icons-material/Twitter'

// MUIS
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'

// STYLES
import useStyles from './footerUseStyles'

const Footer = () => {
  const classes = useStyles()
  const dinamicYear = new Date().getFullYear()

  return (
    <Stack
      alignItems='center'
      backgroundColor='#FFF3F0'
      padding='70px'
    >
      {/* CONTENT */}
      <Stack
        direction='row'
        lineHeight='32px'
        maxWidth='1400px'
        padding='0px 100px'
        width='100%'
      >
        {/* FIRST ROW */}
        <Stack marginRight='157.24px'>
          {/* NLE CONNECT LOGO */}
          <Stack marginBottom='24.41px'>
            <Box
              component='img'
              src={NleConectLogo}
              className={classes.nleConnenctLogo}
            />
          </Stack>
          {/* BY TRANSPORTA */}
          <Stack direction='row'>
            <Stack>
              NLE connect by 
            </Stack>
            <Stack>
              <Box
                component='img'
                src={TransportaLogo}
                className={classes.transportaLogo}
              />
            </Stack>
          </Stack>
          <Stack>Jakarta, Indonesia</Stack>
          {/* SOCIAL MEDIA */}
          <Stack
            paddingTop='31.92px'
            direction='row'
          >
            <Stack maxWidth='24px' width='100%'>
              <LinkedInIcon cursor='pointer'/>
            </Stack>
            <Stack margin='0 26px'>
              <TwitterIcon width='26px' cursor='pointer'/>
            </Stack>
            <Stack>
              <InstagramIcon width='24px' cursor='pointer'/>
            </Stack>
          </Stack>
        </Stack>

        {/* SECOND ROW */}
        <Stack width='149px' marginRight='72px'>
          <Stack className={classes.title}>
            Sumber daya
          </Stack>
          <Stack
            className={classes.link}
            onClick={() => {}}
          >
            Unduh aplikasi
          </Stack>
          <Stack
            className={classes.link}
            onClick={() => {}}
          >
            Cara kerja
          </Stack>
          <Stack
            className={classes.link}
            onClick={() => {}}
          >
            Manfaat
          </Stack>
        </Stack>

        {/* THIRD ROW */}
        <Stack width='109px' marginRight='81px'>
          <Stack className={classes.title}>
            Produk
          </Stack>
          <Stack
            className={classes.link}
            onClick={() => {}}
          >
            Fitur
          </Stack>
          <Stack
            className={classes.link}
            onClick={() => {}}
          >
            Harga
          </Stack>
          <Stack
            className={classes.link}
            onClick={() => {}}
          >
            API
          </Stack>
        </Stack>

        {/* FOURTH ROW */}
        <Stack width='189px' marginRight='21px'>
          <Stack className={classes.title}>
            Tim
          </Stack>
          <Stack
            className={classes.link}
            onClick={() => {}}
          >
            Hubungi kami
          </Stack>
        </Stack>
        
        {/* FIFTH ROW */}
        <Stack width='168px'>
          <Stack
            className={classes.title}
            marginBottom='27.82px'
          >
            Didukung oleh
          </Stack>
          {/* KEMENKEU LOGO */}
          <Stack>
            <Box
              component='img'
              src={KemenkeuLogo}
              className={classes.kemenkeuLogo}
            />
          </Stack>
          {/* NLE LOGO */}
          <Stack>
            <Box
              component='img'
              src={NleLogo}
              className={classes.nleLogo}
            />
          </Stack>
        </Stack>
      </Stack>
      {/* COPYRIGHT */}
      <Stack
        marginTop='63.5px'
        className={classes.copyright}
      >
        Copyright ©NLE Connect by Transporta.id {dinamicYear} All rights reserved
      </Stack>
    </Stack>
  )
}

export default Footer