import { useState, useEffect, useRef } from 'react'

// AOS JS
import AOS from 'aos'
import 'aos/dist/aos.css'

// ASSETS
import JumboImage from 'assets/images/backgrounds/landing-jumbotron-main-image.png'

// GSAP
import { gsap } from 'gsap'

// MUIS
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grow from '@mui/material/Grow'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Slide from '@mui/material/Slide'

// MUI ICONS
import AddIcon from '@mui/icons-material/Add'

// STYLES
import useStyles from './jumbotronUseStyles'

// SERVICE
import { getLandingPageData } from 'services/landingPage'

const JumboTron = () => {
  const classes = useStyles()
  const partnerKementrian = useRef()
  const totalTrip = useRef()
  const totalTransaksi = useRef()

  const [ isRender, setIsRender ] = useState(false)
  const [ listData, setListData ] = useState([])
  const depoDeployedUrl = process.env.REACT_APP_DEPLOYED_DEPO_APP_URL

  const loadJumbotronData = async (inputIsMounted, inputAbortController) => {
    const resultJumbotronData = await getLandingPageData(inputAbortController.signal, 'jumbotron')

    if(resultJumbotronData.jumbotron !== undefined && inputIsMounted) {
      setListData(resultJumbotronData.jumbotron)
    }
  }

  const getRefComponent = (inputIndex) => {
    if(inputIndex === 0) return totalTrip
    else if(inputIndex === 1) return totalTransaksi
    else if(inputIndex === 2) return partnerKementrian
  }

  const handleClickSignUpButton = () => {
    window.open(`${depoDeployedUrl}/sign-up`)
  }
  
  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()

    setIsRender(true)
    loadJumbotronData(isMounted, abortController)
    AOS.init()

    return () => {
      isMounted = false
      abortController.abort()
    }
  }, [])

  useEffect(() => {
    if(listData.data){
      gsap.to(totalTrip.current, {
        duration: 5,
        textContent: listData.data[0].value,
        roundProps: 'textContent',
        ease: 'Power1.easeIn',
      })

      gsap.to(totalTransaksi.current, {
        duration: 5,
        textContent: listData.data[1].value,
        roundProps: 'textContent',
        ease: 'Power1.easeIn',
      })

      gsap.to(partnerKementrian.current, {
        duration: 5,
        textContent: listData.data[2].value,
        roundProps: 'textContent',
        ease: 'Power1.easeIn',
      })
    }
  }, [listData])
  
  return (
    <>
      {/* JUMBOTRON */}
      <Stack 
        className={classes.jumboTronMainContainer}
        alignItems='center'
      >
        <Stack
          direction='row'
          className={classes.jumboTronContainer}
          data-aos='fade-down'
          data-aos-mirror='true'
          data-aos-duration='2000'
          data-aos-anchor-placement='top-center'
        >
          {/* LEFT CONTENT */}
          <Stack
            direction='column'
            justifyContent='flex-start'
            padding='88px 0px'
            maxWidth='570px'
          >
            {/* CAPTION TEXT */}
            <Slide direction='right' in={isRender} timeout={2000}>
              <Typography className={classes.captionText}>
                {listData?.title}
              </Typography>
            </Slide>

            {/* SIGN UP BUTTON */}
            {/*<Grow in={isRender} timeout={2000}>
              <Button 
                variant='contained'
                size='large'
                className={classes.signUpButton}
                onClick={() => handleClickSignUpButton()}
              >
                Daftar
              </Button>
            </Grow>*/}

            {/* TOTAL TRIP */}
            <Stack direction='row' spacing={7}>
              {listData?.data?.map((item,index) => (
                <Stack 
                  direction='column'
                  key={index}
                  width={index === 0 || index === 1 ? '105px' : '140px'}
                >
                  {/* TOTAL NUMBER */}
                  <Stack 
                    direction='row'
                    alignItems='center'
                  >
                    <Typography ref={getRefComponent(index)} className={classes.totalTripNumber}>
                      0
                    </Typography>

                    <AddIcon className={classes.totalTripicon}/>
                  </Stack>

                  {/* TITLE */}
                  <Typography className={classes.totalTripTitle}>
                    {item.title}
                  </Typography>
                </Stack>
              ))}
            </Stack>

          </Stack>

          {/* RIGHT CONTENT */}
          <Stack 
            width='100%'
            direction='row'
            justifyContent='flex-end'
            padding='98px 0px 130px 118.68px'
          >
            <Slide direction='left' in={isRender} timeout={2000}>
              <Box
                component='img'
                src={JumboImage}
                className={classes.jumboImage}
              />
            </Slide>
          </Stack>
        </Stack>
      </Stack>

      {/* COMPANY LOGOS */}
      <Stack alignItems='center' className={classes.logosContainer}>
        <Stack
          direction='row'
          justifyContent='space-between'
          marginBottom='104px'
          maxWidth='1500px'
          padding='0px 50px'
          width='100%'
        >
          {listData?.image?.data.map((item,index) => (
            <Box
              component='img'
              src={`https://nle-strapi.app.lacak.io${item?.attributes?.url}`}
              display='flex'
              key={index}
              data-aos={index % 2 === 0 ? 'zoom-in' : 'zoom-out'}
              data-aos-duration='1000'
            />
          ))}
        </Stack>
      </Stack>
    </>
  )
}

export default JumboTron