// APIS
import { axiosLanding, axiosDepo } from 'api/axios'

export const getLandingPageData = async (
  inputSignal,
  inputQueryParams,
) => {
  try {
    const response = await axiosLanding.get(
      `/api/nle-landing-page?populate[${inputQueryParams}][populate]=*`,
      { 
        signal: inputSignal,
      }
    )
    return response.data.data.attributes
  } 
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const getLandingPageSpecificData = async (
  inputSignal,
  inputQueryParams,
) => {
  try {
    const response = await axiosLanding.get(
      `/api/nle-landing-page?populate[${inputQueryParams}][populate][data][populate]=*`,
      { 
        signal: inputSignal,
      }
    )
    return response.data.data.attributes
  } 
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const postContactUs = async (
  inputSignal,
  inputBodyParams,
) => {
  try {
    const response = await axiosDepo.post(
      '/api/contact-us',
      inputBodyParams,
      { 
        signal: inputSignal,
      }
    )
    return response
  } 
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

