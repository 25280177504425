import React from 'react'
import ReactDOM from 'react-dom/client'

import { BrowserRouter } from 'react-router-dom'

// CONSTANTS
import theme from 'constants/theme'

// COMPONENTS
import App from './App'
import GlobalStyles from 'components/GlobalStyles/GlobalStyles'

// MUIS
import { 
  createTheme,
  StyledEngineProvider, 
  ThemeProvider, 
} from '@mui/material/styles'

// STYLES
import './index.css'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <ThemeProvider theme={createTheme(theme)}>
    <StyledEngineProvider injectFirst>
      <BrowserRouter>
        <GlobalStyles/>
        <App/>
      </BrowserRouter>
    </StyledEngineProvider>
  </ThemeProvider>
)
