import { useState, useEffect } from 'react'

// AOS JS
import AOS from 'aos'
import 'aos/dist/aos.css'

// ASSETS
import BackgroundImage from 'assets/images/backgrounds/landing-solution-background.png'

// MUIS
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

// SERVICE
import { getLandingPageData } from 'services/landingPage'

// STYLES
import useStyles from './solutionUseStyles'
import { Typography } from '@mui/material'

const Solution = () => {
  const classes = useStyles()

  const [ listData, setListData ] = useState([])
  const [ nleUrlLogo, setNleUrlLogo ] = useState('')

  const loadSolutionData = async (inputIsMounted, inputAbortController) => {
    const resultSolutionData = await getLandingPageData(inputAbortController.signal, 'solutions')

    if(resultSolutionData.solutions !== undefined && inputIsMounted) {
      setListData(resultSolutionData?.solutions)
      setNleUrlLogo(resultSolutionData?.solutions?.image?.data[0]?.attributes?.url)
    }
  }

  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()

    loadSolutionData(isMounted, abortController)
    AOS.init()

    return () => {
      isMounted = false
      abortController.abort()
    }
  }, [])
  
  return (
    <Stack 
      marginTop='153px' 
      width='100%'
      alignItems='center'
      sx={{ 
        backgroundImage: `url(${BackgroundImage})`,
        backgroundPosition: 'center'
      }}
    >
      {/* TITLE */}
      <Stack alignItems='center'>
        {/* NLE LOGO */}
        <Box
          component='img'
          src={`https://nle-strapi.app.lacak.io${nleUrlLogo}`}
          className={classes.nleLogo}
          data-aos='fade-down'
          data-aos-offset='-200'
          data-aos-duration='1000'
        />

        {/* TITLE */}
        <Typography 
          className={classes.title1}
          data-aos='fade-right'
          data-aos-offset='-200'
          data-aos-duration='1000'
        >
          {listData?.title}
        </Typography>

        <Typography 
          className={classes.title2}
          data-aos='fade-left'
          data-aos-duration='1000'
          data-aos-offset='-200'
          data-aos-anchor-placement='top-bottom'
        >
          {listData?.description}
        </Typography>
      </Stack>

      {/* MAIN CONTENT */}
      <Stack 
        maxWidth='1500px'
        padding='0px 50px 74px 50px'
        id='main-content'
      >
        <Grid container spacing={3}>
          {listData?.data?.map((item, index) => (
            <Grid 
              key={index} 
              item xs={4}
              data-aos='zoom-in'
              data-aos-duration='1000'
              data-aos-anchor-placement='top-bottom'
              data-aos-offset='-50'
              data-aos-anchor='#main-content'
            >
              <Stack className={classes.cardContainer}>
                <Box className={classes.numberContainer}>
                  <Typography className={classes.numberFontSize}>
                    {item.addition}
                  </Typography>
                </Box>
                <Typography className={classes.cardTitle}>
                  {item.title}
                </Typography>
                <Typography className={classes.cardDescription}>
                  {item.value}
                </Typography>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Stack>

    </Stack>
  )
}

export default Solution