// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  title1: {
    fontSize: 48,
    fontWeight: 600,
    color: theme.palette.text.primary,
    marginBottom: 24,
  },
  title2: {
    fontSize: 22,
    fontWeight: 400,
    color: theme.palette.text.secondary,
    marginBottom: 60,
  },
  faqSummary: {
    fontSize: 20,
    fontWeight: 700,
    color: theme.palette.text.primary,
  },
  faqDetail: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.primary,
  },
}))

export default useStyles