// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  captions: {
    height: 130,
    marginBottom: 72,
    maxWidth: 1500,
    padding: '0px 50px',
    width: '100%'
  },
  captionText: {
    fontSize: 48,
    fontWeight: 700,
    maxWidth: 601,
  },
  detailText: {
    fontSize: 16,
    fontWeight: 400,
    maxWidth: 364,
    paddingTop: 35,
    color: 'rgba(0, 0, 0, 0.6)',
  },
  benefitsCard: {
    border: '1px solid #4DBFAA',
    borderRadius: 4,
    maxHeight: 280,
    padding: 40,
    textAlign: 'left',
    color: theme.palette.text.primary,

    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.secondary.main,
    },
  },
  cardTitle: {
    fontSize: 24,
    fontWeight: 600,
    marginTop: 16,
    marginBottom: 16,
  },
  cardDetail: {
    fontSize: 14,
    fontWeight: 400,
    maxWidth: 510,
    marginBottom: 40,
  }
}))

export default useStyles