import { useState, useEffect } from 'react'

// AOS JS
import AOS from 'aos'
import 'aos/dist/aos.css'

// ASSETS
import BackgroundImage from 'assets/images/backgrounds/landing-benefit-background.png'
import IconDownload from '@mui/icons-material/Download'
import IconPayments from '@mui/icons-material/Payments'
import IconSmartphone from '@mui/icons-material/Smartphone'
import LogoProductNleConnet from 'assets/images/logos/product-nle-connect-icon-only.svg'

// MUI
import { Grid, Stack, Typography } from '@mui/material/'

// SERVICE
import { getLandingPageData } from 'services/landingPage'

// STYLES
import useStyles from './benefitsUseStyles'

const Benefits = () => {
  const classes = useStyles()

  const [ listData, setListData ] = useState([])

  const loadImageComponent = (inputIndex) => {
    if(inputIndex === 0) return <IconDownload sx={{ fontSize:40, color:'#F96649'}}/>
    else if(inputIndex === 1) return <IconPayments sx={{ fontSize:40, color:'#F96649' }}/>
    else if(inputIndex === 2) return <IconSmartphone sx={{ fontSize:40, color:'#F96649' }}/>
    else if(inputIndex === 3) return <img src={LogoProductNleConnet} alt='LogoProductNleConnet' width={66.64} height={35}/>
  }

  const loadBenefitsData = async (inputIsMounted, inputAbortController) => {
    const resultBenefitsData = await getLandingPageData(inputAbortController.signal, 'benefits')

    if(resultBenefitsData.benefits !== undefined && inputIsMounted) {
      setListData(resultBenefitsData.benefits)
    }
  }
  
  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()

    loadBenefitsData(isMounted, abortController)
    AOS.init()

    return () => {
      isMounted = false
      abortController.abort()
    }
  }, [])

  return (
    <>
      {/* BENEFITS */}
      <Stack
        width='100%'
        paddingBottom='310px'
        sx={{ 
          backgroundImage: `url(${BackgroundImage})`,
          backgroundPosition: 'center'
        }}
      >
        {/* CAPTIONS */}
        <Stack 
          width='100%'
          alignItems='center'
          data-aos={'fade-up'}
          data-aos-duration='1000'
        >
          <Stack
            className={classes.captions}
            direction='row'
          >
            {/* LEFT TEXT*/}
            <Stack justifyContent='flex-start'>
              <Typography className={classes.captionText}>
                { listData?.title }
              </Typography>
            </Stack>
          
            {/* RIGHT TEXT*/}
            <Stack
              width='100%'
              direction='row'
              justifyContent='flex-end'
            >
              <Typography className={classes.detailText}>
                { listData?.description }
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      
        {/* BENEFIT CARDS */}
        <Stack width='100%' alignItems='center'>
          <Grid
            maxWidth='1500px'
            padding='0px 50px'
            container
            spacing={3}
          >
            {listData?.data?.map((item, index) =>
              <Grid item key={index} xs={6}>
                <Stack
                  className={classes.benefitsCard}
                  direction='column'
                  data-aos={index % 2 === 0 ? 'fade-up-right' : 'fade-up-left'}
                  data-aos-offset='-10'
                  data-aos-duration='1000'
                >
                  { loadImageComponent(index) }
                  <Typography className={classes.cardTitle}>
                    { item.addition }. { item.title }
                  </Typography>
                  <Typography className={classes.cardDetail}>
                    { item.value }
                  </Typography>
                </Stack>
              </Grid>
            )}
          </Grid>
        </Stack>
      </Stack>
    </>
  )
}

export default Benefits